import React, { Component } from "react";
import PropTypes from "prop-types";
import MapBox from "mapbox";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
//import white from "@material-ui/core/colors/red";
import Grid from "@material-ui/core/Grid";
import BackgroundAddress from "../address/BackgroundAddress";
import BackgroundResults from "../address/BackgroundResults";
//import MuiAlert from '@material-ui/lab/Alert';
const theme = createMuiTheme({
  overrides: {
    MuiTextField: {
      root: {
        "& .MuiOutlinedInput-root": {
          "&:hover fieldset": {
            borderColor: "#4A3090"
          },
          "& fieldset": {
            //borderColor: "lime",
            // borderWidth: 10
          }
        }
      }
    },
    MuiInputBase: {
      root: {
        borderColor: "green",
        borderWidth: 10,
        margin: 100
      }
    },
    MuiOutlinedInput: {
      input: {
        borderColor: "green",
        borderWidth: 8
      }
    },
    MuiInputLabel: {
      root: {
        padding: 0,
        margin: 0
      },
      MuiBox: {
        root: {
          backgroundColor: "#4A3090",
          padding: 100,
          borderRadius: 0
          //color: "#4A3090"
          //borderRadius:"50%",
        }
      }
    },
    typography: {
      fontFamily: "Roboto",
      h5: {
        fontWeight: 500,
        fontSize: "5vw",
        color: "white"
      },
      h6: {
        fontWeight: 500,
        color: "#39307D",
        fontSize: "4.5vw"
      },
      h4: {
        fontFamily: "Roboto-Bold",
        fontStyle: "bold"
      }
    },
    palette: {
      primary: { 500: "#4A3090" },
      secondary: { main: "rgba(0,0,0,0.38)" }
    }
  }
});

export default class TypeAhead extends Component {
  static propTypes = {
    onLocationUpdate: PropTypes.func.isRequired,
    onLocationSelect: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      request: "",
      locations: [],
      locationSelected: false,
      selectedLocation: null
    };

    this.mapbox = new MapBox(process.env.REACT_APP_MAPBOX_API_TOKEN);

    // Binding functions
    this.handleLocationUpdate = this.handleLocationUpdate.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSelectLocation = this.handleSelectLocation.bind(this);
    this.resetSearch = this.resetSearch.bind(this);
    this.updateStateFromChild = this.updateStateFromChild.bind(this);
  }

  /** Lifecylce events */
  componentDidUpdate(prevProps, preState) {
    console.log("updateing state");
    if (preState.request === "" && preState.locations.length) {
      //this.setState(() => ({ locations: [] }));
    }
  }

  componentDidMount() {
    this.resetSearch();
  }

  /** Mapbox API calls */
  handleLocationUpdate(location) {
    this.setState(() => {
      return {
        request: location.name,
        locations: [],
        selectedLocation: location
      };
    });

    this.props.onLocationUpdate(location);
  }

  updateStateFromChild(e) {
    this.setState(() => ({ locationSelected: e }));
  }

  handleSearchChange(e) {
    console.log("--" + e + "--");
    this.mapbox.geocodeForward(e, {}).then(loc => {
      if (!loc.entity.features || !loc.entity.features.length) {
        return;
      }
      console.log("Weirdness: " + JSON.stringify(loc.entity.features));
      const locations = loc.entity.features.map(feature => {
        const [lng, lat] = feature.center;
        return {
          name: feature.place_name,
          lat,
          lng
        };
      });
      console.log(locations);
      if (locations) {
        this.setState(() => ({ locations }));
      }
    });
  }

  resetSearch() {
    this.setState(() => {
      return {
        request: "",
        locations: [],
        locationSelected: false,
        selectedLocation: null
      };
    });
  }

  handleSelectLocation(e, flag = false) {
    let coords = this.state.selectedLocation;
    if (flag) coords = e;
    console.log(coords);
    this.props.onLocationSelect(coords);
    this.setState(() => ({
      request: e.name,
      locations: [],
      locationSelected: true,
      selectedLocation: coords
    }));
  }

  render() {
    return [
      <MuiThemeProvider key="provider" theme={theme}>
        <Grid
          className="app"
          container
          color="primary"
          key="background-paper"
          className="background-paper"
        >
          <BackgroundAddress
            key={this.state.request}
            name={this.state.request}
            handleSearch={this.handleSearchChange}
            handleClick={this.handleSelectLocation}
            handleSubmission={this.props.onLocationSelect}
            locations={this.state.locations}
            location={this.state.selectedLocation}
            locationSelected={this.state.locationSelected}
            updateProps={this.updateStateFromChild}
          />
          <BackgroundResults
            key="type-ahead-res"
            className="type-ahead-res"
            locations={this.state.locations}
            onLocationSelected={this.handleSelectLocation}
          />
        </Grid>
      </MuiThemeProvider>
    ];
  }
}
