import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  card: {
    width: "100%"
  },
  title: {
    fontSize: "1rem",
    fontWeight: 600
  },
  button: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
    background: "#3E2E83",
    color: "white",
    fontFamily: "'Work Sans', sans-serif",
    fontWeight: 500,
    "&:hover": {
      background: "transparent",
      border: "2px solid #3E2E83",
      color: "#3E2E83"
    },
    textTransform: "none"
  }
});

export default function WordpressButton(props) {
  const classes = useStyles();

  return (
    <Button
      className={classes.button}
      type={props.type}
      variant="contained"
      disableElevation
    >
      Search
    </Button>
  );
}
