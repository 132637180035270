import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  TextField,
  Typography,
  ThemeProvider,
  FormControl
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import "typeface-roboto";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationArrow } from "@fortawesome/free-solid-svg-icons";
import InputAdornment from "@material-ui/core/InputAdornment";
import Box from "@material-ui/core/Box";
import TurfOp from "../turf/TurfOp";
import Button from "../utils/wordpress-button";
export default class BackgroundAddress extends Component {
  static propTypes = {
    name: PropTypes.string,
    request: PropTypes.string,
    handleSubmission: PropTypes.func.isRequired,
    handleSearch: PropTypes.func.isRequired,
    locations: PropTypes.array,
    location: PropTypes.object,
    locationSelected: PropTypes.bool,
    handleClick: PropTypes.func.isRequired,
    updateProps: PropTypes.func.isRequired
  };

  static defaultProps = {
    name: "empty",
    request: "",
    locations: [],
    locationSelected: false
  };

  constructor(props) {
    super(props);
    this.state = {
      name: "",
      request: "",
      handleSearch: props.handleSearch,
      handleSubmission: props.handleSubmission
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitSearch = this.handleSubmitSearch.bind(this);
  }

  handleChange(event) {
    console.log("My Location: " + this.props.location);
    if (this.props.location) this.props.updateProps(false);
    const request = event.target.value;
    this.setState({ request });
    if (!request) return;
    console.log("--" + request + "--");
    this.state.handleSearch(request);
  }

  handleSubmit(event) {
    // do some validation here and notify the use that the address is not valid or somehting
    //alert("A name was submitted: " + JSON.stringify(this.props.locations[0]));
    event.preventDefault();
    if (this.props.locations[0]) {
      this.props.handleClick(this.props.locations[0], true);
    }
  }

  handleSubmitSearch() {
    this.state.handleSubmission(this.state.request);
    console.log("Button click: " + this.state.request);
  }
  render() {
    return [
      <Grid
        key="container"
        className="removespace"
        container
        direction="column"
        spacing={3}
        justify="space-around"
        alignItems="stretch"
      >
        {/**<Grid item container className="img_cont" justify="flex-end" xs={12}>
                      <img className="logo-white" src="static/images/1x/logo_white.png"></img>
          </Grid>**/}
        {/**<Grid container direction="row" justify="space-between">
	    <Grid item xs={8}>
            <Typography variant="h5" component="h3" className="type-content">
              Are you in District 9?
            </Typography>
          </Grid>
          
	    <Grid container>
            {this.props.locationSelected && (
              <TurfOp
                key="op"
                location={this.props.location}
                locationSelected={this.props.locationSelected}
              />
            )}
          </Grid>
        </Grid>**/}
        <Grid className="removespace">
          <Box className="type-content expand-width input-area">
            <form id="myform" onSubmit={this.handleSubmit}>
              <TextField
                className="textField"
                placeholder="10101 SW 152nd St, Miami FL 33157"
                fullWidth={true}
                variant="outlined"
                value={this.state.request}
                onChange={this.handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment className="ipt" position="start">
                      <FontAwesomeIcon
                        size="sm"
                        color="#3D2D82"
                        icon={faLocationArrow}
                      />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment className="button" position="end">
                      <Button type="submit">Search</Button>
                    </InputAdornment>
                  ),
                  style: { padding: "2vw", margin: 0, marginTop: 8 }
                }}
              />
            </form>
          </Box>
        </Grid>
        <Grid className="alert" direction="row">
          {this.props.locationSelected && [
            <TurfOp
              key="op"
              location={this.props.location}
              locationSelected={this.props.locationSelected}
            />
          ]}
        </Grid>
      </Grid>
    ];
  }
}
