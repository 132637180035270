import React, { Component } from "react";
import PropTypes from "prop-types";
import { List, ListItem, ListItemText, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";

export default class BackgroundResults extends Component {
  static propTypes = {
    locations: PropTypes.array,
    onLocationUpdate: PropTypes.func,
    onLocationSelected: PropTypes.func
  };

  static defaultProps = {
    locations: [
      { name: "Intelligencia", lng: 32.2343, lat: 12.2312 },
      { name: "Starbucks", lng: 72.2343, lat: 22.2312 }
    ]
  };

  constructor(props) {
    super(props);
    this.state = {
      locations: props.locations
    };
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  handleOnClick(e) {
    console.log(e);
    this.props.onLocationSelected(e, true);
  }

  render() {
    return [
      <List
        dense
        component="li"
        key="results"
        className="list_results"
        p={10}
        m={0}
        //disablePadding={true}
      >
        {this.props.locations.map(location => {
          return (
            <ListItem
              className="removespace"
              button
              key={location.name}
              onClick={() => this.handleOnClick(location)}
            >
              <ListItemText
                primary={
                  <Typography
                    variant="body1"
                    style={{
                      color: "#333333",
                      marginBottom: -3,
                      fontSize: 18
                    }}
                  >
                    {location.name}
                  </Typography>
                }
                //secondary={<Typography variant="body2" style={{ fontFamily: 'Roboto-Light', color: '#CFC2F4', marginTop:-8, fontSize: "1.65vw"}}>Secondary text</Typography>}
              />
            </ListItem>
          );
        })}
      </List>
    ];
  }
}
