import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles({
  card: {
    width: "100%",
    background: "#F8F9F9"
  },
  title: {
    color: "#3E2E83",
    fontSize: "1rem",
    fontWeight: 600,
    textAlign: "left"
  },
  body: {
    color: "#3E2E83"
  },
  button: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
    background: "#3E2E83",
    color: "white",
    fontFamily: "'Work Sans', sans-serif",
    fontWeight: 500,
    "&:hover": {
      background: "transparent",
      border: "2px solid #3E2E83",
      color: "#3E2E83"
    },
    textTransform: "none"
  }
});

export default function TurfResults(props) {
  const classes = useStyles();

  return (
    <Card className={classes.card} variant="outlined">
      <CardActionArea>
        <CardContent>
          <Typography
            gutterBottom
            className={classes.title}
            color="textSecondary"
            component="h2"
          >
            You entered: {props.address}
          </Typography>
          <Typography
            variant="h5"
            className={classes.body}
            color="textSecondary"
            component="p"
          >
            {props.message.data}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            className="button-res"
            variant="contained"
            size="medium"
            href="https://secure.anedot.com/luisa-santos-campaign/donate"
            disableElevation
          >
            Volunteer
          </Button>
          <Button
            className="button-res"
            variant="contained"
            size="medium"
            href="https://luisasantos.com/volunteering/"
            disableElevation
          >
            Donate
          </Button>
        </CardActions>
      </CardActionArea>
    </Card>
  );
}
