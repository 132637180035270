import React, { Component } from "react";
import axios from "axios";
import * as turf from "@turf/turf";
import { Button, Typography, Grid } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import TurfResults from "../turf/TurfResults";

const successMessage = {
  data:
    "The address you entered is in District 9. On August 18th, you can vote for Luisa! In the meantime, you can also donate or volunteer.",
  flag: true
};
const failureMessage = {
  data:
    "While you don't live in District 9, you can still support this campaign by volunteering or donating.",
  flag: false
};

export default class TurfOp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: false,
      data: "",
      district: null,
      district9FilePath: process.env.REACT_APP_DISTRICT
    };
    this.loadDistrict = this.loadDistrict.bind(this);
    this.getRes = this.getRes.bind(this);
  }

  componentDidMount() {
    console.log("Did Mount");
    console.log(this.props.location);
    //this.setState({data: "yellow", district9FilePath : '/static/data/district9.json'})
    if (this.state.district === null) {
      console.log("District not saved");
      this.loadDistrict();
    } else {
      console.log("District saved");
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    console.log(`shouldComponentUpdate`);
    console.log(nextProps, nextState);
    return true;
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.name !== prevProps.location.name) {
      console.log("---------------------");
      console.log("Props are different");
      console.log(this.props);
      console.log(prevProps);
      this.getRes();
      console.log("---------------------");
    }
  }

  loadDistrict() {
    const lat = this.props.location.lat;
    const lng = this.props.location.lng;
    console.log("Lat/Long: " + lat + ", " + lng);
    axios
      .get(this.state.district9FilePath)
      .then(res => {
        console.log("Filtering data:" + res.data + ":");
        return res.data;
      })
      .catch(err => console.log(err))
      .then(v => {
        var pt = turf.point([lng, lat]);
        var poly = turf.lineToPolygon(turf.lineString(v[0]));
        console.log(lng, lat);
        var res = turf.booleanPointInPolygon(pt, poly);
        console.log(res);
        this.setState(() => ({
          district: poly,
          result: res
        }));
      })
      .catch(err => console.log(err));
  }

  getRes() {
    var pt = turf.point([this.props.location.lng, this.props.location.lat]);
    console.log(this.state.district);
    var res = turf.booleanPointInPolygon(pt, this.state.district);
    console.log(res);
    this.setState(() => ({
      result: res
    }));
    //turf.booleanPointInPolygon(turf.point([-80.5531467831852,25.6665636515432])
  }

  render() {
    return [
      //<Results key="results" isVisible={true}/>
      <Grid container>
        {this.state.result ? (
          <TurfResults
            address={this.props.location.name}
            message={successMessage}
          />
        ) : (
          <TurfResults
            address={this.props.location.name}
            message={failureMessage}
          />
        )}
      </Grid>
      //{JSON.stringify(toTurfPolygon(multiLineString(this.state.data)))}</div>
    ];
  }
}
