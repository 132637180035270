import React, { Component } from "react";
import PropTypes from "prop-types";
import TypeAhead from "./components/typeahead/TypeAhead";
import { Grid, Divider, ThemeProvider, MuiDivider } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";

import logo from "./logo.svg";
import "./App.css";

const theme = createMuiTheme({
  overrides: {
    MuiDivider: {
      root: {
        width: "100%",
        backgroundColor: "green"
      }
    }
  }
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.name
    };
    this.doOnLocationSelect = this.doOnLocationSelect.bind(this);
    this.doOnLocationUpdate = this.doOnLocationUpdate.bind(this);
  }

  static propTypes = {
    name: PropTypes.string
  };

  doOnLocationUpdate() {}

  doOnLocationSelect() {}

  render() {
    return [
      <div key="app" className="app">
        {/* <h1> Hello World! </h1>
		<Grid item xs={12}><Address name={this.state.name} geoRequest={this.makeGeocodeRequest}/></Grid> */}
        <TypeAhead
          onLocationUpdate={this.doOnLocationUpdate}
          onLocationSelect={this.doOnLocationSelect}
        />

        {/*<Grid container justify="center">
          {[0, 1, 2].map(value => (
            <Grid key={value} item>
              <Results /> />
		  </Grid>
          ))}*/}
      </div>
    ];
  }
}

export default App;
